var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"content-fix org"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('a-button',{staticClass:"back-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'pressCenter' })}}},[_c('i',{staticClass:"t-transition icons icon-chevron-left"}),_vm._v(" Назад ")]),_c('a-row',{staticClass:"post-search",attrs:{"type":"flex"}},[_c('a-select',{staticClass:"table__parpage",attrs:{"option-filter-prop":"children","placeholder":"Все"},on:{"change":_vm.tableperPage},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.LimitList),function(limit,index){return _c('a-select-option',{key:index,attrs:{"value":limit.value}},[_vm._v(" "+_vm._s(limit.title)+" ")])}),1),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'public-council-create' })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.$t("Add"))+" ")],1)],1),_c('a-row',{staticStyle:{"width":"100%","justify-content":"space-between"},attrs:{"type":"flex"}},[_c('h1',[_vm._v(_vm._s(_vm.title[_vm.$i18n.locale]))]),_c('a-button',{attrs:{"disabled":_vm.cardsIsEmpty,"type":"danger"},on:{"click":function($event){return _vm.removeItem(_vm.selectedPosts)}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)],1),_c('a-table',{key:_vm.$route.fullPath,attrs:{"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableProps.results,"pagination":false,"scroll":{ x: 2200, y: 600 },"row-key":(record) => record.id},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('a-checkbox',{attrs:{"checked":_vm.selectedPosts.includes(+item.id),"value":+item.id},on:{"change":function($event){return _vm.toggleItemId(item.id)}}}),_c('span',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
            name: 'public-council-detail',
            params: { id: item.id }
          })}}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"order",fn:function(item){return [_c('a-input-number',{staticStyle:{"width":"60px","margin-right":"10px"},attrs:{"min":1},on:{"pressEnter":function($event){return _vm.updatePosition(item, $event)}},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"photo",fn:function(item){return [(!item.thumb)?_c('div',[_vm._v("no photo")]):_c('div',[_c('img',{staticStyle:{"width":"80px","height":"80px","object-fit":"cover"},attrs:{"alt":"profile_img","src":item.thumb}})])]}},{key:"full_name",fn:function(item){return [_c('div',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
            name: 'public-council-detail',
            params: { id: item.id }
          })}}},[_c('b',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(item.full_name))])])]}},{key:"slug",fn:function(item){return [_vm._v(" "+_vm._s(item.slug)+" ")]}},{key:"activity",fn:function(item){return [_vm._v(" "+_vm._s(item.activity || "-")+" ")]}},{key:"phone_number",fn:function(item){return [_vm._v(" "+_vm._s(item.phone_number || "-")+" ")]}},{key:"author",fn:function(item){return [_vm._v(" "+_vm._s(item.author || "-")+" ")]}},{key:"status",fn:function(item){return [_c('a-tag',{attrs:{"color":item.is_active ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(item.is_active ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")])]}},{key:"created_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"updated_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("LLL"))+" ")]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push(
            {
              name: 'public-council-detail',
              params: { id: item.id }
            },
            () => null
          )}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1)]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{attrs:{"current":_vm.page,"default-page-size":_vm.perPage,"total":+_vm.tableProps.count || 0},on:{"change":_vm.toPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }